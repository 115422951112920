import React, { useContext, useEffect, useState } from "react";
import Project from "./Project";
import projectList from "../assets/projectList";
import { BoxContext } from "../context/context";
import { Link } from "react-router-dom";
import BlobOutlines from "./BlobOutlines";

export default function Projects() {
  const [colour, setColour] = useState("white");
  const ctx = useContext(BoxContext);

  // variable title changes text and colour based on context

  const colourPicker = () => {
    if (ctx.title === "Projects" || ctx.title === "Thanks for coming.") {
      return setColour("white");
    } else if (ctx.project.key % 2 === 0) {
      setColour("#ff7373");
    } else {
      setColour("#11a6ad");
    }
  };

  useEffect(() => {
    colourPicker();
  }, [ctx.title, ctx.project.key]);

  return (
    <div className='projects-and-variable-title'>
      <div className='variable-title' onClick={() => window.scrollTo(0, 0)}>
        <Link
          to={`/project/${ctx.project.key || "0"}`}
          onClick={() => window.scrollTo(0, 0)}
          style={
            { color: `${colour}` }
            // ctx.title !== "Projects" ? { color: "#ff7373" } : { color: "white" }
          }
        >
          {ctx.title}
        </Link>
      </div>

      <div className='projects'>
        {/* variable title is the sticky scrolling header
           the project components each have function that changes the state of the text through Context when viewed */}

        <BlobOutlines />

        {projectList.map((project, index) => {
          return <Project project={project} index={index} key={project.key} />;
        })}
      </div>
    </div>
  );
}
