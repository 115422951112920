import React from "react";

export default function BlobOutlines() {
  return (
    <div className='blob-outlines'>
      <svg
        id='teal-outline'
        viewBox='0 0 453 376'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M278.978 11.8085L374.578 54.1843C421.407 74.9419 451.6 121.351 451.6 172.575V203.425C451.6 254.649 421.407 301.058 374.578 321.816L278.978 364.191C245.561 379.004 207.439 379.004 174.022 364.191L78.4222 321.816C31.5933 301.058 1.40038 254.649 1.40038 203.425V172.575C1.40038 121.351 31.5933 74.9419 78.4222 54.1843L174.022 11.8085C207.439 -3.00421 245.561 -3.00421 278.978 11.8085Z'
          stroke='#11A6AD'
          strokeOpacity='0.8'
        />
      </svg>
      <svg
        id='pink-outline'
        viewBox='0 0 453 376'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M278.978 11.8085L374.578 54.1843C421.407 74.9419 451.6 121.351 451.6 172.575V203.425C451.6 254.649 421.407 301.058 374.578 321.816L278.978 364.191C245.561 379.004 207.439 379.004 174.022 364.191L78.4222 321.816C31.5933 301.058 1.40038 254.649 1.40038 203.425V172.575C1.40038 121.351 31.5933 74.9419 78.4222 54.1843L174.022 11.8085C207.439 -3.00421 245.561 -3.00421 278.978 11.8085Z'
          stroke='#FF7373'
          strokeOpacity='0.8'
        />
      </svg>
      <svg
        id='teal-outline2'
        viewBox='0 0 453 376'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M278.978 11.8085L374.578 54.1843C421.407 74.9419 451.6 121.351 451.6 172.575V203.425C451.6 254.649 421.407 301.058 374.578 321.816L278.978 364.191C245.561 379.004 207.439 379.004 174.022 364.191L78.4222 321.816C31.5933 301.058 1.40038 254.649 1.40038 203.425V172.575C1.40038 121.351 31.5933 74.9419 78.4222 54.1843L174.022 11.8085C207.439 -3.00421 245.561 -3.00421 278.978 11.8085Z'
          stroke='#11A6AD'
          strokeOpacity='0.8'
        />
      </svg>
      <svg
        id='pink-outline2'
        viewBox='0 0 453 376'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M278.978 11.8085L374.578 54.1843C421.407 74.9419 451.6 121.351 451.6 172.575V203.425C451.6 254.649 421.407 301.058 374.578 321.816L278.978 364.191C245.561 379.004 207.439 379.004 174.022 364.191L78.4222 321.816C31.5933 301.058 1.40038 254.649 1.40038 203.425V172.575C1.40038 121.351 31.5933 74.9419 78.4222 54.1843L174.022 11.8085C207.439 -3.00421 245.561 -3.00421 278.978 11.8085Z'
          stroke='#FF7373'
          strokeOpacity='0.8'
        />
      </svg>
      <svg
        id='teal-outline3'
        viewBox='0 0 453 376'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M278.978 11.8085L374.578 54.1843C421.407 74.9419 451.6 121.351 451.6 172.575V203.425C451.6 254.649 421.407 301.058 374.578 321.816L278.978 364.191C245.561 379.004 207.439 379.004 174.022 364.191L78.4222 321.816C31.5933 301.058 1.40038 254.649 1.40038 203.425V172.575C1.40038 121.351 31.5933 74.9419 78.4222 54.1843L174.022 11.8085C207.439 -3.00421 245.561 -3.00421 278.978 11.8085Z'
          stroke='#11A6AD'
          strokeOpacity='0.8'
        />
      </svg>
    </div>
  );
}
