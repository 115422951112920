import React from "react";
import Projects from "./Projects";
import TitleMessage from "./TitleMessage";

export default function Main() {
  return (
    <div className='main'>
      <TitleMessage />
      <Projects />
    </div>
  );
}
