import React, { useContext, useEffect } from "react";
import useOnScreen from "./useOnScreen";
import { BoxContext } from "../context/context";
import { Link } from "react-router-dom";
import Blobs from "./Blobs";
import BlobOutlines from "./BlobOutlines";

export default function TitleMessage() {
  const ctx = useContext(BoxContext);

  const [setRef, visible] = useOnScreen({ threshhold: 1 });

  useEffect(() => {
    visible && ctx.setTitle("Projects");
  }, [visible]);

  return (
    <div className='title-dashboard-space' ref={setRef}>
      <Blobs />

      <div className='glad-youre-here'>
        <h1>I'm glad</h1>
        <h1>
          you're <i>here</i>.
        </h1>
      </div>
      <div className='dark-text-div'>
        <p>
          I’m Oskar, a{" "}
          <a
            href='https://github.com/raksozorw'
            target='_blank'
            rel='noopener noreferrer'
          >
            web developer
          </a>{" "}
          based in London. <br></br>
          Some examples of <Link to='/project/0'>my work </Link>are below.{" "}
          <br></br> See my{" "}
          <a
            href='https://github.com/raksozorw'
            target='_blank'
            rel='noopener noreferrer'
          >
            {" "}
            Github
          </a>{" "}
          for the code.
          <br></br> <a href='mailto:oskarwroz@gmail.com'>Contact me </a>for work
          enquiries by email.
        </p>
      </div>
    </div>
  );
}
