import React from "react";

export default function Blobs() {
  return (
    <div className='blobs'>
      <svg
        id='pink-svg'
        viewBox='0 0 461 458'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M186.171 18.897C210.723 -5.4159 250.277 -5.41589 274.829 18.897L441.794 184.235C466.689 208.887 466.689 249.113 441.794 273.765L274.829 439.103C250.277 463.416 210.723 463.416 186.171 439.103L19.2059 273.765C-5.68867 249.113 -5.68866 208.887 19.206 184.235L186.171 18.897Z'
          fill='#FF7373'
          fillOpacity='0.8'
        />
      </svg>
      <svg
        id='teal-svg'
        viewBox='0 0 530 357'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M235.833 7.10416C254.257 -1.80514 275.743 -1.80513 294.167 7.10417L491.675 102.611C525.076 118.762 538.992 158.982 522.716 192.322L460.87 319.005C449.637 342.017 426.269 356.612 400.662 356.612H129.338C103.731 356.612 80.3634 342.017 69.1296 319.005L7.2842 192.322C-8.9921 158.982 4.92428 118.762 38.3252 102.611L235.833 7.10416Z'
          fill='#11A6AD'
          fillOpacity='0.8'
        />
      </svg>
    </div>
  );
}
